import React, { useRef, useState } from "react";
import "./ImageComponent.scss";
const ImageComponent = ({ src }) => {
  const ChipStyles = useRef({
    position: "absolute",
    //top: "50%",
    //left: "50%",
    width: "auto",
    maxHeight: "30vh",
    position: "absolute",
    top: `${Math.floor(Math.random() * 50) + 50}%`,
    left: `${Math.floor(Math.random() * 50) + 50}%`,
    //transform: "translate(-100%, -100%)",
  });

  return <img src={src} style={ChipStyles.current} className="image" alt="" />;
};

export default ImageComponent;
