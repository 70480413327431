import logo from "./logo.svg";
import "./App.scss";
import image1 from "./assets/1.webp";
import image2 from "./assets/2.webp";
import image3 from "./assets/3.webp";
import image4 from "./assets/4.webp";
import image5 from "./assets/5.webp";
import image6 from "./assets/6.webp";
import image7 from "./assets/7.webp";
import image8 from "./assets/8.webp";
import image9 from "./assets/9.webp";
import image10 from "./assets/10.webp";
import image11 from "./assets/11.webp";
import image12 from "./assets/12.webp";
import image13 from "./assets/13.webp";
import image14 from "./assets/14.webp";
import image15 from "./assets/15.webp";
import image16 from "./assets/16.webp";

import { useRef, useEffect, useState } from "react";
import ImageComponent from "./components/ImageComponent";

export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

function App() {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const scrollRef = useHorizontalScroll();

  return (
    <>
      {matches ? (
        <div className="App" ref={scrollRef}>
          <div className="container" id="scrollableElement">
            <div className="imageContainer">
              <ImageComponent src={image1} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image2} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image3} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image4} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image5} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image6} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image7} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image8} />
            </div>
          </div>
          <div className="container" id="scrollableElement">
            <div className="imageContainer">
              <ImageComponent src={image9} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image10} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image11} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image12} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image13} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image14} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image15} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image16} />
            </div>
          </div>
        </div>
      ) : (
        <div className="App">
          <div className="container" id="scrollableElement">
            <div className="imageContainer">
              <ImageComponent src={image1} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image2} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image3} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image4} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image5} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image6} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image7} />
            </div>
            <div className="imageContainer">
              <ImageComponent src={image8} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
